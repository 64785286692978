import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'tpIdParserPipe',
})
export class TpIdParserPipe implements PipeTransform {
  transform(value: string): string {
    const searchString = '5443504c'
    const index = value.indexOf(searchString)
    if (index !== -1) {
      return `TP${this.hexToDecimal(value.substring(index + 2 + searchString.length))}`
    }
    return value
  }

  hexToDecimal(hex: string): string {
    const decimal = parseInt(hex, 16)
    let result = `${decimal}`
    while (result.length < 7) {
      result = `0${result}`
    }
    return result
  }
}
