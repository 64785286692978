import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'locationKind',
})
export class LocationKindPipe implements PipeTransform {
  transform(stock: any, kind: any): string | undefined {
    if (!stock.place?.length || kind == 'TICKET_PALLET' || kind == 'TOTE') {
      return ''
    }
    const places = stock.place?.filter((p: { kind: any }) => p.kind == kind)
    return places.length ? places[0].label : ''
  }
}
