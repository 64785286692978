import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map, Observable, of } from 'rxjs'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import {
  Location,
  LocationSearchParams,
  LocationsBulkUpdate,
} from './location.model'
import { Product } from '../products/product.model'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'locations'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class LocationsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
  }

  /**
   * Read a location by ID
   * @param locationId - The location ID
   * @returns The observable<Location> for read the location
   */
  read$(locationId: string): Observable<Location> {
    return this._read$<Location>(locationId)
  }

  /**
   * Update a location by ID
   * @param locationId - The location ID
   * @param location - The location body to update
   * @returns The observable<Location> for update the location
   */
  update$(locationId: string, location: Location): Observable<Location> {
    return this._update$<Location>(locationId, location)
  }

  /**
   * Create or update a location by ID
   * @param locationId - The location ID
   * @param location - The location body to update
   * @returns The observable<Location> for update the location
   */
  upsert$(location: Location): Observable<Location> {
    return this._upsert$<Location>(location, location._id)
  }

  /**
   * Search locations by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Location>> for search locations
   */
  search$(
    params?: LocationSearchParams,
    returnAll = false,
  ): Observable<Page<Location>> {
    return this._search$<Location>(params, returnAll)
  }

  /**
   * List locations by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Location[]> for list locations
   */
  list$(
    params?: LocationSearchParams,
    returnAll = false,
  ): Observable<Location[]> {
    return this._list$<Location>(params, returnAll)
  }

  /**
   * Move the products from a location to another
   * @param sourceLocationId - The source location ID
   * @param targetLocationId - The target location ID
   * @returns the observable<void> for location move
   */
  move$(sourceLocationId: string, targetLocationId: string): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/move/from/${sourceLocationId}/to/${targetLocationId}`,
      {},
    )
  }

  /**
   * Download the location label
   * @param locationId - The location ID
   * @returns the observable<any> for download the location label
   */
  downloadLabel$(
    location: Location,
  ): Observable<{ blob: Blob; label: string }> {
    return this.http
      .get(`${this.apiUrl}/${location._id}/label`, {
        responseType: 'blob',
      })
      .pipe(map((blob) => ({ blob, label: `label-${location.code}.pdf` })))
  }

  /**
   * Print the location label
   * @param locationId - The location ID
   * @param printerId - The printer ID
   * @returns the observable<void> to print the location label
   */
  printLabel$(locationId: string, printerId: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${locationId}/label/print`, {
      printerId,
    })
  }

  /**
   * Print the location labels
   * @param locationIds - The location ids
   * @param printerId - The printer ID
   * @returns the observable<void> to print the location labels
   */
  printLabels$(locationIds: string[], printerId: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/labels/print`, {
      locationIds,
      printerId,
    })
  }

  /**
   * Get location products
   * @param locationId - The location ID
   * @param params - The pagination params
   * @returns the observable<Product[]> for list location products
   */
  products$(
    locationId: string,
    params?: { limit?: number; offset?: number },
  ): Observable<Page<Product>> {
    return this.http.get<Page<Product>>(
      `${this.apiUrl}/${locationId}/products`,
      {
        params,
      },
    )
  }

  /**
   * Set a location as start-point
   * @deprecated
   * @param locationId - The location ID
   * @returns the observable<>
   */
  setAsStartPoint$(locationId: string): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/${locationId}`, {
      isAreaStart: true,
    })
  }

  /**
   * Search a single location by CODE
   * @param code - The CODE to search
   * @returns the observable<Location> to search a single location
   */
  readOne$(code: string): Observable<Location> {
    return this._readOne$<Location>({ code })
  }

  /**
   * Empty a location by its id
   * @param locationId - The location ID
   * @param params - The action params
   * @returns the observable<{ productIds: string[] }> to empty the location
   */
  empty$(
    locationId: string,
    params: {
      reasonId: string
      notes?: string
    },
  ): Observable<{ productIds: string[] }> {
    return this.http.post<{ productIds: string[] }>(
      `${this.apiUrl}/${locationId}/empty`,
      params,
    )
  }

  /**
   * Bulk update locations
   * @param params - the update params
   * @returns the observable<{ taskId: string }> for update locations
   */
  bulkUpdate$(params: LocationsBulkUpdate): Observable<{ taskId: string }> {
    return this.http.post<{ taskId: string }>(
      `${this.apiUrl}/bulk-update`,
      params,
    )
  }

  /**
   * Generate locations store
   * @param locationIds - the location IDs to load
   * @param locations - the locations already loaded
   * @returns the Observable<Location[]> as store
   */
  store$(locationIds: string[], locations: Location[]): Observable<Location[]> {
    locationIds = uniq(locationIds)
    locationIds = difference(
      locationIds,
      locations.map((u) => u._id),
    )

    if (locationIds.length === 0) {
      return of(locations)
    }

    return this.list$({ _id: locationIds }, true).pipe(
      map((locs) => [...locations, ...locs]),
    )
  }

  /**
   * Print ticket pallets label
   * @param warehouseId - The warehouse ID
   * @param printerId - The printer ID
   * @param qty - The number of labels to print
   * @returns the observable<void> to print the product label
   */
  printTicketPallet$(
    warehouseId: string,
    printParams: {
      printerId: string
      qty: number
    },
  ): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/ticket-pallets`, {
      warehouseId: warehouseId,
      printerId: printParams.printerId,
      count: printParams.qty,
    })
  }
}
