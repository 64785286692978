import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { LocationModel } from '../location-models'
import { Edge, Vertex } from '../locations/location.model'

export interface Warehouse extends Entity {
  name: string
  externalId?: string

  address: WarehouseAddress
  email?: string
  phone?: string
  shippingInfo?: WarehouseShippingInfo

  // Carriers
  carriers?: string[]
  defaultCarrier?: string

  // Locations
  locationId?: string
  packagingLocationId?: string
  areas: WarehouseArea[]
  totes: WarehouseTotes

  /**
   * True when some external tenant can access this Warehouse.
   */
  isShared?: boolean
}

export interface WarehouseAddress {
  name?: string
  address1?: string
  address2?: string
  city?: string
  province?: string
  postalCode?: string
  countryCode?: string
}

export interface WarehouseShippingInfo {
  address?: {
    name?: string
    address1: string
    address2?: string
    city: string
    province?: string
    postalCode: string
    country?: string
    countryCode: string
  }
  email?: string
  phone?: string
}

export interface WarehouseArea extends WarehouseAreaSettings {
  _id: string
  label: string
}

export interface WarehouseTotes {
  count: number
  labelType: 'NUMBERS' | 'LETTERS'
  labelPrefix?: string
  labelPadding?: number
}

export interface WarehouseLocation {
  lat?: string
  lng?: string
}

export interface WarehouseGraph {
  vertices: Vertex[]
  edges: Edge[]
}

export interface WarehouseTotes {
  labelPadding?: number
  labelPrefix?: string
  labelType: 'NUMBERS' | 'LETTERS'
  count: number
}

export interface WarehouseAreaSettings {
  aisles?: WarehouseAreaLevel
  racks?: WarehouseAreaLevel
  shelves?: WarehouseAreaLevel
  positions?: WarehouseAreaLevel
  compartments?: WarehouseAreaLevel
  narrowAisles?: boolean
  shiftAisles?: boolean
  initialLocationId?: string
  levelsOrdering?: string[]
  isPlaced?: boolean
  forScraps?: boolean
}

export interface WarehouseAreaLevel {
  /**
   * Number of aisles inside this Area.
   */
  count: number
  /**
   *
   */
  singularNoun: string
  /**
   *
   */
  pluralNoun: string
  /**
   * The type for the generated Locations' labels.
   */
  labelType: 'NUMBERS' | 'LETTERS'
  /**
   * Add a prefix to the generated Location's label.
   */
  labelPrefix?: string
  /**
   * Padding of zeros when `labelType` is `"NUMBER"`.
   */
  labelPadding?: number
}

export interface WarehouseInvitation {
  tenant: {
    _id: string
    name: string
  }
  createdAt: string
  createdBy: string
}

export type WarehouseNotificationCode =
  | 'AREA_DELETION'
  | 'AREA_SAVED'
  | 'AREA_DELETED'
  | 'TOTES_SAVED'
  | 'MODEL_DELETION'
  | 'MODEL_SAVED'
  | 'MODEL_DELETED'
  | 'WAREHOUSE_DELETION'
  | 'WAREHOUSE_DELETED'
  | 'WAREHOUSE_INCOMPLETE'
  | 'WAREHOUSE_SAVED'
  | 'TENANT_INVITED'
  | 'TENANT_REJECTED'
  | 'TICKET_PALLET_PRINTED'
  | 'LOCATIONS_PRINTED'
  | 'LOCATIONS_UPDATED'

export interface WarehouseNotificationOptions {
  code: WarehouseNotificationCode
  data?: WarehouseNotificationData
}

export interface WarehouseNotificationData {
  warehouse?: Warehouse
  area?: WarehouseArea
  model?: LocationModel
}

export type WarehouseSearchField =
  | '_id'
  | 'name'
  | 'externalId'
  | 'address.name'
  | 'address.address1'
  | 'address.city'
  | 'address.province'
  | 'address.countryCode'
  | 'address.postalCode'
export type WarehouseSortField = 'name'

export interface WarehouseSearchParams
  extends QueryStringParams<WarehouseSortField> {
  name?: string
  'name:ne'?: string
  'name:ct'?: string
  externalId?: string
  'externalId:ne'?: string
  'externalId:ct'?: string
  'address.name'?: string
  'address.name:ne'?: string
  'address.name:ct'?: string
  'address.address1'?: string
  'address.address1:ne'?: string
  'address.address1:ct'?: string
  'address.city'?: string
  'address.city:ne'?: string
  'address.city:ct'?: string
  'address.province'?: string | string[]
  'address.province:ne'?: string | string[]
  'address.countryCode'?: string | string[]
  'address.countryCode:ne'?: string | string[]
  'address.postalCode'?: string
  'address.postalCode:ne'?: string
}

export enum WarehouseTab {
  structure = 'structure',
  logistic = 'logistic',
}

export type WarehouseAreaCreationParams = Omit<WarehouseArea, '_id'>
