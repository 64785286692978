import { Pipe, PipeTransform } from '@angular/core'
import { Location } from '../location.model'

@Pipe({
  name: 'pathOrDock',
})
export class PathOrDockPipe implements PipeTransform {
  transform(
    locationId: string | undefined,
    locations: Location[] | undefined,
    docks: Location[] | undefined,
  ): string {
    if (!locationId || !locations?.filter((l) => l._id == locationId).length) {
      return ''
    }
    const location = locations?.filter((l) => l._id == locationId)[0]
    if (
      !location?.dockId ||
      !docks?.filter((l) => l._id == location.dockId).length
    ) {
      return location.path
    }
    const dock: Location | undefined = docks?.filter(
      (l) => l._id == location.dockId,
    )[0]
    if (!dock) {
      return `${location.path} [?]`
    }
    return `${location.path} [${dock.path}]`
  }
}
