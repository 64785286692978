export const LANG_IT_NAME = 'it'

export const LANG_IT_TRANS = {
  INVALID_OBJECT_ID: 'ID documento non valido',
  MISSING_OBJECT_ID: 'ID documento non presente',
  EMPTY_DOCUMENT: 'Documento vuoto',
  DOCUMENT_VALIDATION_ERROR: 'Documento non valido',
  MISSING_UNIQUE_KEY: 'Chiave univoca mancante',
  MISSING_AUTH_DATA: 'Autenticazione mancante',
  CREDENTIALS_NOT_VALID: 'Credenziali non valide',
  MISSING_MANDATORY_PRAMETER: 'Dati obbligatori mancanti',
  TOKEN_NOT_VALID: 'Autenticazione non valida',
  DOCUMENT_NOT_FOUND: 'Documento non trovato',
  INCOMPATIBLE_CHANGE_STATUS: 'Azione non valida',
  DUPLICATED_DOCUMENT: 'Documento duplicato',
  REMOTE_UNREACHABLE: 'Servizio momentaneamente non disponibile',
  REMOTE_API_ERROR: 'Errore API esterna',
  GENERIC_ERROR: 'Errore generico',
  MALFORMED_BODY: 'Richiesta non valida',
  ERROR_CALLING_EXTERNAL_API: 'Errore API corriere',
  'Something went wrong. Try again later.':
    'Ops, qualcosa è andato storto. Riprova più tardi',
  'A classification with this name already exists':
    'Classificazione già esistente',
  'Can add orders from closed bordereau':
    'Non è possibile aggiungere ordini ad un bordereau in stato SPEDITO',
  'Can remove orders from closed bordereau':
    'non è possibile rimuovere ordini da un bordereau in stato SPEDITO',
  'Cannot ship more quantity than ordererd':
    'Non puoi spedire più quantità di quella ordinata',
  'Cant add specified product or same location is already set':
    'Impossibile aggiungere prodotto o locazione già presente',
  'Cant change status': 'Impossibile cambiare stato',
  'Cant delete closed bordereau':
    'Non è possibile eliminare bordereau in stato SPEDITO',
  'Cant find generic carrier for this warehouse ':
    'Corriere generico non settato per questo magazzino',
  'Cant find shipment for the specified order':
    'Nessuna spedizione trovata per questo ordine',
  'Cant find specifed product': 'Prodotto non trovato',
  'Cant find specified location': 'Locazione non trovata',
  'Cant find specified order': 'Ordine non trovato',
  'Cant find specified payment type':
    'Impossibile trovare il pagamento specificato',
  'Cant find specified product': 'Prodotto non trovato',
  'Cant find valid user role': 'Ruole utente non valido',
  'Cant find valid user session': 'Sessione utente non valida',
  'Document is not unique': 'Il documento non è univoco',
  'Error during token decoding': 'Errore durante la decodifica del token',
  'ID list is not an array': 'ID list non è un array',
  'Invalid attribute ID': 'ID attributo non valido',
  'Invalid ID': 'ID non valido',
  'Invalid location label': 'Etichetta locazione non valida',
  'Invalid mission ID': 'ID missione non valido',
  'Invalid mission status update': 'Aggiornato stato missione non valido',
  'Invalid options': 'Opzione non valida',
  'Invalid order ID': 'ID ordine non valido',
  'Invalid picking list ID': 'ID picking list non trovato',
  'Invalid product ID': 'ID prodotto non valido',
  'Invalid reason ID': 'ID causale non valido',
  'Invalid request body': 'Invalid request body',
  'Invalid resource ID': 'ID risorsa non valido',
  'Invalid tag ID': 'Tag ID non valido',
  'Invalid value': 'Valore non valido',
  'Invalid value entry': 'Valore non valido',
  'KITs cant have location': 'KIT non possono avere locazioni',
  'Login failed': 'Login fallito',
  'Login failed: provide a username and password':
    'Login fallito: inserire nome utente e password',
  'Malformed mission': 'Missione malformata',
  'Malformed picking list data': 'Dati picking list malformati',
  'Malformed picking list orders data': 'Dati ordini picking list malformati',
  'Missing auth token': 'Token di autorizzazione mancante',
  'Missing brands id': 'ID brand non trovato',
  'Missing categories id': 'ID categoria mancante',
  'Missing mandatory param': 'Parametro obblgiatorio mancante',
  'Missing mandatory parameters': 'Manca un parametro obbligatorio',
  'Missing manufacturers id': 'Manca ID produttore',
  'Missing product ID': 'Manca ID del prodotto',
  'Missing query parameters': 'Parametro query mancante',
  'Missing tote code': 'Manca codice UDC',
  'Missing user': 'Utente mancante',
  'Missing value': 'Valore mancante',
  'Missing value label': 'Manca valore etichetta',
  'Mission product not found': 'Prodotto non trovato per la missione',
  'More than one product found': 'Prodotti multipli trovati',
  'Movements must be under transaction':
    'I movimenti devono essere contenuti in una transaction',
  'No active session found': 'Nessuna sessione attiva',
  'No available actions': 'Non ci sono azioni disponibili.',
  'No product found': 'Prodotto non trovato',
  'Order is not confirmed': "L'ordine non è in stato CONFERMATO",
  'Order not found': 'Ordine non trovato',
  'Packing list is not in processing status':
    'La packing list non è IN LAVORAZIONE',
  'Picking list not in processing status':
    'La picking list non è IN LAVORAZIONE',
  'Pickinglist orders property must be an array':
    'La proprietà orders nelle pickinglist deve essere un array',
  'Product not found': 'Prodotto non trovato',
  'Product to move does not exists': 'Prodotto da trasferire non esistente',
  'Provide +|- to use with $inc operator':
    "Utilizza +|-|= per usare l'operatore  $inc",
  'Provide +|-|= to use with $inc operator':
    "Utilizza +|-|= per usare l'operatore  $inc",
  'Provide a valid username': 'Inserisci un nome utente valido',
  'Qty to move is negative': 'Quantità da trasferire negativa',
  'Qty to move is not an integer':
    'La quantità da trasferiere non è un intero positivo',
  'Quantity must be a positive integer':
    'La quantità deve essere un numero intero positivo',
  'Quantity must me an integer':
    'Quantità deve essere un numero intero positivo',
  'Quantity must me positive': 'Quantità deve essere un numero positivo',
  'Quantity parameter is mandatory': 'La quantità è obbligatoria',
  'Reason not compatible with movement':
    'Causale non compatibile con il movimento',
  'Reason not found': 'Causale di movimentazione non trovata',
  'Select options not an array': '',
  'Some nodes are not reachable from the starting point':
    'Alcuni nodi non sono raggiungibili dal punto di partenza',
  'Source and target location are the same':
    'Stessa locazione di partenza e destinazione',
  'Source location does not exists': 'La locazione di partenza non esiste',
  'Source location not found': 'Locazione di partenza non trovata',
  'Starting location is mandatory for building pickpath ':
    'Locazione di partenza obbligatoria per generare il percorso di picking',
  'StartVertex is missing from graph': 'StartVertex mancante',
  'Target attribute not found': '',
  'Target classification not found': '',
  'Target location does not exists': 'La locazione di destinazione non esiste',
  'Target location not found': 'Locazione di destinazione non trovata',
  'Target order not found': '',
  'Target packing list not found': '',
  'Target picking list not found': 'Picking list non trovata',
  'Target product location has not picked items': '',
  'Target product not found': '',
  'Target tag not found': '',
  'Target warehouse not found': 'Magazzino di destinazione non trovato',
  'The attribute value is an empty string': 'Inserire un valore valido',
  'The attribute value is not a boolean': 'Il valore non è un booleano',
  'The attribute value is not a number':
    'Il valore inserito deve essere un numero',
  'The attribute value is not a string': 'Il valore non è una stringa',
  'The attribute value is not an integer':
    'Il valore inserito deve essere un intero',
  'The attribute value is too long': 'Valore attributo troppo lungo',
  'The attribute value is too short': 'Valore attributo troppo corto',
  'The attribute value must be a finite number':
    'Il valore deve essere un numero',
  'The old password provided is wrong':
    'La vecchia password inserita non è valida',
  'The parent location must be root':
    'La locazione padre deve essere la radice',
  'The picked qty must be an integer':
    'La quantità deve essere un  numero intero',
  'The picked qty must be positive or zero':
    'La quantità deve essere un numero intero positivo',
  'The picking list is not in PENDING status':
    'La picking list non è in stato DA PRELEVARE',
  'The source location does not contains this product':
    'Prodotto non presente nella locazione di partenza',
  'The source location does not have enough qty to move':
    'Quantità da trasferire maggiore della giacenza',
  'The source location is reserved':
    'Sono presenti prodotti riservati ad una picking list',
  'The value have to be an object': 'Il valore deve essere un oggetto',
  'There are attribute values': 'Sono presenti dei valori per questo attributo',
  'There are child entities connected to this classification':
    'Classificazioni figlie associate',
  'There are inventory items that are assigned to this warehouse':
    'Sono presenti prodotti assegnati a questo magazzino',
  'There are locations with connected products':
    'Il prodotto è presente in alcuni locazioni',
  'There are missing picking locations Check warehouse structure':
    'Locazioni picking mancanti. Controllare struttura magazzino',
  'There are orders that contains this product':
    'Il prodotto è presente in alcuni ordini',
  'There are orders with this payment type':
    'Sono presenti ordini con il metodo di pagamento specificato',
  'There are prodcuts that uses this brand':
    'Prodotti collegati a questo brand',
  'There are prodcuts that uses this manufacturer':
    'Prodotti collegati a questo produttore',
  'There are products connected to this classification':
    'Prodotti collegati a questa classificazione',
  'There are users items that are assigned to this warehouse':
    'Sono presenti utenti assegnati a questo magazzino',
  'There is already a brand with that name': 'Brand già presente',
  'There is already a manufacturer with that name': 'Produttore già presente',
  'There is already an active session': 'Sessione utente già attiva',
  'There is already an attribute with that code':
    'È già presente un attributo con questo codice',
  'There is more than one shipment for this order':
    'È presente più di una spedizione per questo ordine',
  'There is no sufficient stock left': "Non c'è sufficiente giacenza",
  'There is not a currently active packing list':
    'Non ci sono packing list attive',
  'This action is not available': 'Azione non disponibile',
  'This order is inside a picking list':
    "L'ordine è inserito in una packing list",
  'This packing list already exists': 'Packing list già esistente',
  'This tenant doesnt have any ftp set':
    'Questo tenant non ha profili FTP associati',
  'This tenant hasnt any carriers':
    'Il tenant non ha nessun corriere impostato',
  'This tote is used by another picking list':
    "UDC utilizzata per un'altra picking list",
  'This value is already assigned': 'Valore già assegnato',
  'Token not valid.': 'Token non valido',
  'Too much qty packed': 'Quantità imballata maggiore della richiesta',
  'Tote used by another order': 'UDC utilizzata per un altro ordine',
  'Unable to a valid action': '',
  'Unable to delete this category': 'Impossibile eliminare categoria',
  'Unable to delete this tag': 'Non è possibile cancellare il tag',
  'Unable to fetch product data': 'Impossibile reperire informazioni prodotto',
  'Unable to fetch product info':
    'Impossibile ottenere informazioni del prodotto',
  'Unable to find a valid mission for the current order':
    "Impossibile trovare missioni valide per l'ordine corrente",
  'Unable to find a valid picking list':
    'Impossibile trovare una picking list valida',
  'Unable to find a valid picking list to update':
    'Impossibile trovare una picking list da aggiornare',
  'Unable to find Amazon and eBay channels for the current tenant':
    'Impossibile trovare canale Amazon e eBay per questo tenant',
  'Unable to find the current mission': 'Impossibil trovare missione corrente',
  'Unable to find the current order': 'Impossibile trovare ordine corrente',
  'Unable to find the Ecommerce channel for the current tenant':
    'Impossibile trovare canale Ecommerce per questo tenant',
  'Unable to find the packed order': "Impossibile trovare l'ordine imballato",
  'Unable to find the packing list to update':
    'Impossibile trovare packing list da aggiornare',
  'Unable to find the picking list': 'Picking list non trovata',
  'Unable to find the product': 'Prodotto non trovato',
  'Unable to find the target tote': 'Impossibile trovare UDC di destinazione',
  'Unable to find the tote inside product locations':
    'UDC non trovata nelle locazioni prodotto',
  'Unable to load tote information': 'Impossibile reperire informazioni UDC',
  'Unexpected quantity to pack': 'Quantità da imballare sbagliata',
  'Unexpected tenant': 'Unexpected tenant',
  'Unknown action': 'Azione sconosciuta',
  'User not found': 'Utente non trovato',
  'User permissions are too low to access this resource':
    'Permessi utenti insufficienti per accedere a questa risorsa',
  'Wrong password provided': 'Password errata',
  'Wrong product': 'Prodotto errato',
  'Wrong tote': 'UDC errata',
  'Xlsx file header doesnt match the template file':
    'Il file non corrisponde con il template di importazione',
  'You are not authorized to access this resource':
    'Accesso alla risorsa non autorizzato',
  'You can only test when the picking list is in PENDING status':
    'La simulazione evasione è attiva solo in stato DA PRELEVARE',
  'You cannot change the attribute code':
    'Non è possibile cambiare il codice attributo',
  'You cannot change the attribute kind':
    'Non è possibile cambiare il tipo di attributo',
  'You cannot make an existing attribute unique':
    'Non è possibile settare un attributo esistente come univoco',
  'You cannot skip the last mission': "Non puoi saltare l'ultima missione",
  'You cannot skip the last order': "Non puoi saltare l'ultimo ordine",
  'You have to close the current packing list':
    "Devi concludere l'imballaggio della packinglist corrente prima di iniziare con un'altra",
  'You have to specify a custom reason':
    'Specificare causale di movimentazione',
  'Not Found': 'Elemento non trovato',
  'There are orders that contains this product.':
    'Ci sono ordini che contengono questo prodotto',
  'Missing pickinglocation from the warehouse.':
    'Ubicazioni di prelievo mancanti per questo magazzino',
  'Missing required attribute': 'Configurazioni obbligatorie mancanti',
  'rcpt_prov: deve essere lungo al massimo 2 caratteri':
    'Provincia indirizzo di spedizione non valido',
  'dmo-availability': 'DMO export DISPONIBILITA',
  'dmo-bolced': 'DMO import BOLCED',
  'dmo-carced': 'DMO export CARCED',
  'dmo-invced': 'DMO export INVCED',
  'dmo-magart': 'DMO import MAGART',
  'dmo-mapping': 'DMO import MAPPINGSKU-CART',
  'dmo-ordcli': 'DMO Export ORDCLI',
  'dmo-orders': 'DMO import ORDINI Ecommerce',
  'dmo-tracking': 'DMO export TRACKING',
  'dmo-status': 'DMO export STATUS ordini',
  'trilab-dropship': 'Ordini Droshipping',
  'trilab-magento-shipments': 'Magento shipments',
  'trilab-store360-orders': 'Ordini Store360',
  'trilab-magento-orders': 'Ordini Magento',
  'trilab-store360-shipments': 'Store360 shipments',
  'trilab-magento-availability': 'Disponibilità ecommerce',
  'trilab-excel-availability': 'Catalogo Excel',
  'amica-category': 'Export CATEGORY',
  'amica-eavs': 'Export EAVS',
  'amica-ite': 'Export ITE',
  'amica-prices': 'Export PRICES',
  'amica-stock': 'Export STOCK',
  'bene-exp-legacy': 'Export prodotti',
  'bene-imp-legacy': 'Import ordini',
  'Unable to pack this product': 'Non è possibile imballare questo prodotto',
  ORDERS_FILE: 'File ordini',
  ROWS_FILE: 'File righe',
  FTP_HOST: 'Host',
  FTP_PORT: 'Porta',
  FTP_USERNAME: 'Username',
  FTP_PASSWORD: 'Password',
  FTP_ACTION: 'Azione FTP',
  SOURCE_FILE: 'Nome file:',
  SOURCE_DIRECTORY: 'Cartella sorgente',
  SOURCE_PATTERN: 'Pattern sorgente',
  TARGET_FILE: 'Nome file destinazione',
  TARGET_DIRECTORY: 'Cartella destinazione',
  TARGET_PATTERN: 'Pattern destinazione',
  RECEIVER_MAIL: 'Email',
  'Unable to close the order if there is at least one unopened goods arrival':
    "Non è possibile chiudere l'ordine a fornitore se sono presenti degli arrivi merce ancora in lavorazione",
  'Found a product location not castable':
    'Prodotto impegnato in pickinglist o in attesa di imballaggio',
  "Can't find location on the product.":
    'Ubicazione non trovata per questo prodotto',
  'Product is not managed by batches.': 'Il prodotto non è gestito a lotti',
  'This location has reservedQty > 0.':
    'Ubicazione con quantità riservate a picking list',
  'New signature is already present in the batches.':
    "Lotto già presente nell'ubicazione selezionata",
  'Invalid newQty: must be minor than old batch onHandQty.':
    "Quantità non valida: dev'essere minore alla giacenza",
  'Invalid newQty: must be > 0.':
    "Quantità non valida: dev'essere maggiore di 0",
  'Provide two different batches.':
    'É necessario indicare un lotto diverso da quello già presente',
  'Unable to reserve this batch':
    'Non è stato possibile riservare la quantità richiesta',
  'Tote already used by another picking list':
    "UDC già utilizzata da un'altra picking list",
  'Unable to retrieve the required entity': 'Elemento non trovato',
  'ERRORE CORRIERE PRODUZIONE ETICHETTA':
    'Controllare indirizzo spedizione ordine (lunghezza max 35 caratteri, indirizzo/provincia non valida, caratteri speciali, etc.)',
  WAREHOUSE_ID: 'Magazzino',
  FULL_CATALOG: 'Intero catalogo',
  Warehouse: 'Magazzino',
  EMUT_INVALID_WRITE: 'Documento non valido',
  'Cannot update an entity with an invalid value':
    'Controllare che tutti i valori del documento siano validi',
  EMUT_INVALID_READ: 'Documento non valido',
  'Cannot read an entity with an invalid value':
    'Il documento che si sta cercando di leggere non è valido',
  INVALID_CREDENTIALS: 'Credenziali non valide',
  'Invalid credentials': 'Username e/o password errati',
  NOT_AUTHORIZED: 'Non autorizzato',
  "You haven't the authorization to perform this action":
    'Non sei autorizzato ad effettuare questa azione',
  EMUT_INVALID_DATA: 'Documento non valido',
  'Unusable data found': 'Struttura documento non valida',
  EMUT_INVALID_ENTITY: 'Documento non valido',
  'Current entity does not match the configured schema':
    'Struttura documento non valida',
  NOT_AUTHENTICATED: 'Non autenticato',
  'Session was invalidated': 'Sessione scaduta',
  INTERNAL_ERROR: 'Richiesta non eseguita',
  'An error occurred':
    "Qualcosa è andato storto durante l'esecuzione della richiesta",
  PASSWORD_RESET_REQUIRES_EMAIL: 'Utente senza email associata',
  'This account does not have an email configured':
    'Per poter resettare la password è necessaria una email associata al proprio profilo.',
  CHANGE_PASSWORD_REQUIRED: 'Aggiornamento password richiesto',
  'The current password must be changed':
    "E' necessario aggiornare la password corrente",
  USER_WRONG_PASSWORD: 'Password corrente errata',
  'The provided password is wrong': 'La password indicata è errata',
  'Current access token was invalidated':
    'La sessione corrente non è più valida',
  ROLE_USED: 'Ruolo assegnato',
  'This role is currently used by some users.':
    'Questo ruolo è attualmente assegnato ad utenti',
  'There are child products linked to this product':
    'Ci sono delle varianti collegate a questo prodotto',
  'Product linked to a parent product':
    'Prodotto collegato ad un prodotto padre',
  'You need to end the packing list to do so.':
    "E' necessario terminare la packing list per poter annullare l'ordine.",
  OP_ATTRIBUTE_IS_USED: 'Attributo utilizzato',
  'This attribute is currently used by some products':
    'Questo attributo è attualmente utilizzato in alcuni prodotti.',
  DISABLED_USER: 'Accesso non consentito',
  'User disabled': 'Utente disabilitato',
  'Wrong entity status': "Stato documento non valido per l'azione richiesta",
  'User was disabled': 'Utente disabilitato',
  'This feature is not available with legacy authentication':
    'Funzionalità non disponibile',
  'Access token not provided': 'Sessione scaduta',
  'This token is not usable for this URL':
    'Token non utilizzabile per questa richiesta',
  'You do not posses the required permissions to perform this action.':
    "Non possiedi i permessi necessari per eseguire l'azione",
  'Unable to delete this category, there are child categories':
    'Categoria padre di altre categorie.',
  'Unable to delete this category, there are connected products':
    'Categoria assegnata a prodotti.',
  "Can't find shipment for the specified order.":
    "Nessuna etichetta trovata per quest'ordine",
  VALIDATION_ERROR: 'Documento non valido',
  'Unable to delete this family, there are products using it.':
    'Famiglia assegnata a prodotti.',
  OP_PACKING_PARTIAL: 'Imballo parziale',
  'This order is not fully packed': 'Ordine non completamente imballato.',
  'body.file.mimetype should be equal to constant':
    'Estensione file non accettata.',
  'The server refuse to process the request':
    "Non è stato possibile effettuare l'operazione.",
  MAIL_RECIPIENTS: 'Email destinatari',
  'You cannot remove all orders from this bordereau':
    'Non è possibile rimuovere tutti gli ordini da una distinta.',
  'Unable to delete this group, there are connected attributes':
    'Non è possibile eliminare questo gruppo, sono presenti degli attributi collegati.',
  Italian: 'Italiano',
  'Postal code is required for GLS shipments.':
    'CAP obbligatorio per spedizioni GLS.',
  'Province is required for GLS shipments.':
    'Provincia obbligatoria per spedizioni GLS.',
  'Missing carrier service.': 'Servizio corriere richiesto.',
  'Shipping address must have a postal code.':
    'CAP indirizzo di spedizione richiesto.',
  'Shipping address must have a phone number.':
    'Numero di telefono indirizzo di spedizione richiesto.',
  'Shipping address must have an email address.':
    'Email indirizzo di spedizione richiesto.',
  'Current warehouse must have a name.':
    'Indirizzo di partenza senza un valido nome (magazzino di gestione).',
  'Current warehouse must have a phone number.':
    'Indirizzo di partenza senza un valido numero di telefono (magazzino di gestione).',
  'Current warehouse must have an email.':
    'Indirizzo di partenza senza una valida email (magazzino di gestione).',
  'Cannot cancel this shipment.':
    'Non è possibile annullare questa spedizione.',
  'Shipment canceling is not supported for this carrier.':
    'Non è possibile annullare questa spedizione per questo corriere.',
  'The currenct shipment does not match with carrier definition.':
    "La spedizione corrente non appartiene all'attuale corriere associato.",
  'Unexpected carrier.': 'Corriere non previsto.',
  'This order does not contain any package.':
    "Non ci sono colli assegnati a quest'ordine.",
  'Billind address required.': 'Indirizzo di fatturazione richiesto.',
  'Shipping address required.': 'Indirizzo di spedizione richiesto.',
  'Recipient name required.': 'Nome destinatario richiesto.',
  'Recipient address required.': 'Indirizzo destinatario richiesto.',
  'Recipient city required.': 'Città destinatario richiesto.',
  'Recipient country code required.': 'Stato destinatario richiesto.',
  'FedEx needs a service.': 'Servizio FedEx richiesto.',
  'FedEx service not found inside the carrier data.':
    'Servizio FedEx non presente tra quelli disponibili.',
  'This label is not contained inside this shipment.':
    'Etichetta non presente nella spedizione generata dal corriere.',
  'Shipment label file not found. This could be normal for old labels.':
    'Etichetta non presente nella spedizione generata dal corriere.',
  GOODS_RECEIVE_LINKED: 'Arrivo merce collegato',
  'Cannot remove a linked row':
    'Non è possibile rimuovere una riga collegata ad un arrivo merce',
  OP_PRODUCT_NOT_FOUND: 'Prodotto non trovato',
  'No products found with this code.':
    'Nessun prodotto trovato con questo codice',
  'An error has occurred.':
    "Si è verificato un errore durante l'elaborazione della richiesta",
  Save: 'Salva',
  'Save and close': 'Salva e chiudi',
  'Active filters': 'Filtri attivi',
  Reload: 'Ricarica',
  'Reset selection': 'Resetta selezione',
  'Download excel file': 'Scarica file excel',
  'Download an excel file of the filtered items':
    'Scarica un file excel degli elementi filtrati',
  'Download an excel file of the selected items':
    'Scarica un file excel degli elementi selezionati',
  'New webhook': 'Nuovo webhook',
  'Create a new webhook': 'Crea un nuovo webhook',
  'selected webhooks': 'webhook selezionati',
  Settings: 'Impostazioni',
  'Mandatory fields': 'Campi obbligatori',
  Event: 'Evento',
  'Webhook status': 'Stato webhook',
  'Select the emission status of the webhook':
    'Seleziona lo stato di emissione del webhook',
  Url: 'Url',
  'Enter an url': 'Inserisci un url',
  'Minimum 16 characters required': 'Minimo 16 caratteri richiesti',
  'Invalid url or with insecure protocol (requires https)':
    'Url non valido o con protocollo non sicuro (richiede https)',
  'View webhook': 'Visualizza webhook',
  'Modify webhook': 'Modifica webhook',
  'Delete webhook': 'Elimina webhook',
  'There are no webhooks': 'Non ci sono webhook',
  'Add webhooks to be able to perform operations':
    'Aggiungi dei webhook per poter effettuare delle operazioni',
  'There are no elements that match your search':
    'Non ci sono elementi che soddisfano la tua ricerca',
  'Please try again by resetting or modifying your search':
    'Riprova resettando o modificando la ricerca',
  'Oops, it seems there was an error loading the elements':
    'Ops, sembra ci sia stato un errore nel caricamento degli elementi',
  'Check that your internet connection is working and try reloading the page':
    'Controlla che il tuo collegamento ad internet funzioni e prova a ricaricare la pagina',
  'Webhook configuration': 'Configurazione webhook',
  Typology: 'Tipologia',
  Cancel: 'Annulla',
  Continue: 'Continua',
  Back: 'Indietro',
  'There is already a brand with that name.':
    'Esiste già un brand con il nome indicato',
  GSPED_ERROR: 'Errore stampa etichetta',
  UNKNOWN_ERROR: 'Errore sconosciuto',
  'Unknown Error': "Non è stato possibile eseguire l'operazione richiesta",
  SUCCESS: 'Successo',
  FAILED: 'Fallito',
  CARRIER_LOCKED: 'Corriere non modificabile',
  "Cannot change this order's carrier during this status.":
    "Non è possibile modificare il corriere a causa dello stato dell'ordine",
  EXPECTED_TOTE_LOCATION: 'Ubicazione errata',
  'You can pick only with tote locations.':
    'È possibile prelevare sono con UDC',
  TOTE_ALREADY_USED: 'UDC già utilizzata',
  'This tote was already used by another order.':
    'Questa UDC è già stata utilizzata per un altro ordine.',
  'The requested resource does not exist.': 'La rotta richiesta non esiste.',
  USER_SAME_PASSWORD: 'Password combaciano',
  'The new password must be different from the old one.':
    "La nuova password dev'essere diversa dalla password attuale",
  "You haven't the authorization to perform this action.":
    'Non hai i permessi necessari per effettuare questa richiesta.',
  'querystring/q must NOT have fewer than 3 characters':
    'Inserire almeno 3 caratteri',
  CONFIRMED: 'Confermato',
  PROCESSING: 'In lavorazione',
  PACKED: 'Imballata',
  PRINTED: 'Chiusa',
  PROCESSED: 'Processato',
  ASSIGNED: 'Assegnato',
  RECEIVED: 'Ricevuto',
  RETURNING: 'In attesa',
  CLOSED: 'Chiuso',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel',
  "body/printers must have required property 'labels'":
    'Seleziona una stampante per le etichette.',
  "body/printers must have required property 'a4'":
    'Seleziona una stampante A4',
  Views: 'Viste',
  'Download views filtered file': 'Scarica un file excel delle viste filtrate',
  'New view': 'Nuova vista',
  'Create a new view': 'Crea una nuova vista',
  'selected views': 'viste selezionate',
  'Reload page': 'Ricarica pagina',
  'View configuration': 'Configurazione vista',
  Products: 'Prodotti',
  Fields: 'Campi',
  Field: 'Campo',
  'Customer orders': 'Ordini clienti',
  'Supplier orders': 'Ordini fornitori',
  'Goods receives': 'Arrivi merce',
  Sales: 'Vendite',
  'Picking lists': 'Picking list',
  'New custom view': 'Nuova vista personalizzata',
  'Main data': 'Dati principali',
  Name: 'Nome',
  'Name of the view': 'Nome della vista',
  'Shared with other users': 'Condivisa con altri utenti',
  'Table columns': 'Colonne della tabella',
  Filters: 'Filtri',
  Parameters: 'Parametri',
  'Custom view': 'Vista personalizzata',
  'Add field': 'Aggiungi campo',
  'Add attribute': 'Aggiungi attributo',
  Columns: 'Colonne',
  'New field': 'Nuovo campo',
  'New attribute': 'Nuovo attributo',
  'No fields configured': 'Non ci sono campi configurati',
  'Add filter': 'Aggiungi filtro',
  'New filter': 'Nuovo filtro',
  'No filters configured': 'Non ci sono filtri configurati',
  Operator: 'Operatore',
  Currency: 'Valuta',
  Value: 'Valore',
  'No params configured': 'Non ci sono parametri configurati',
  'Add param': 'Aggiungi parametro',
  'New param': 'Nuovo parametro',
  Catalog: 'Catalogo',
  Supplier: 'Fornitore',
  'Invalid invoiced quantity': 'Quantità fatturata non valida',
  CONFLICTING_STATUS_CHANGE: 'Richiesta non completata',
  Param: 'Parametro',
  UNAUTHORIZED: 'Non autorizzato',
  'Views management': 'Gestione viste',
  'Views available': 'Viste disponibili',
  'All groups': 'Tutti i gruppi',
  All: 'Tutto',
  ALL: 'Tutto',
  Attribute: 'Attributo',
  'Related products': 'Prodotti associati',
  Attention: 'Attenzione',
  'Brand saved successfully': 'Brand salvato con successo',
  'Fill out the form with all the necessary data':
    'Compilare il form con tutti i dati necessari',
  'View name': 'Nome vista',
  'Field required': 'Campo richiesto',
  'Set default fields': 'Imposta campi di default',
  'Set default': 'Imposta default',
  "body must have required property 'name'": 'Campo "nome" richiesto',
  true: 'Vero',
  false: 'Falso',
  Navigation: 'Navigazione',
  General: 'Generale',
  Areas: 'Aree',
  Locations: 'Ubicazioni',
  Totes: 'Unità di carico',
  Carriers: 'Corrieri',
  'New warehouse': 'Nuovo magazzino',
  'Clear selection': 'Annulla selezione',
  'warehouses selected': 'magazzini selezionati',
  'Download file': 'Scarica file',
  'Download file XLS': 'Scarica file XLS',
  'Create a new warehouse': 'Crea un nuovo magazzino',
  'Actions available': 'Azioni disponibili',
  'Print labels': 'Stampa etichette',
  'Back to the list': 'Torna alla lista',
  'Save warehouse': 'Salva il magazzino',
  'Save warehouse %s': 'Salva il magazzino %s',
  'Save the warehouse %s and go back':
    'Salva il magazzino %s e torna alla lista',
  Structure: 'Struttura',
  Logistic: 'Logistica',
  'Add carrier': 'Aggiungi corriere',
  'No carriers linked': 'Nessun corriere collegato',
  Carrier: 'Corriere',
  'Link carrier': 'Collega corriere',
  'Set carrier %s as default': 'Imposta %s come corriere di default',
  'Unlink carrier %s from warehouse': 'Rimuovi %s come corriere del magazzino',
  'Link a carrier to the warehouse': 'Collega un corriere al magazzino',
  'Warehouse saved successfully': 'Magazzino salvato con successo',
  'No areas configured': 'Nessuna area configurata',
  'Add area': 'Aggiungi area',
  'Totes amount': 'Quantità UDC',
  'Add totes': 'Aggiungi UDC',
  Warehouses: 'Magazzini',
  warehouses: 'magazzini',
  'Own warehouse': 'Magazzino proprio',
  'Shared warehouse': 'Magazzino condiviso',
  'External ref.': 'Rif. esterno',
  'External reference': 'Riferimento esterno',
  MODIFIED: 'MODIFICATO',
  Type: 'Tipologia',
  'Label length': 'Lung. codice',
  NUMBERS: 'NUMERI',
  LETTERS: 'LETTERE',
  'New area': 'Nuova area',
  Aisle: 'Corsia',
  Aisles: 'Corsie',
  Rack: 'Scaffale',
  Racks: 'Scaffali',
  Shelve: 'Livello',
  Shelves: 'Livelli',
  Positions: 'Posizioni',
  Position: 'Posizione',
  Compartments: 'Scomparti',
  Compartment: 'Scomparto',
  'Add %s': 'Aggiungi %s',
  'Amount of %s': 'Quantità di %s',
  'Configure %s': 'Configura %s',
  'Picking method': 'Modalità di prelievo',
  Disposition: 'Disposizione',
  'Area saved successfully': 'Area salvata con successo',
  'Area deleted successfully': 'Area eliminata con successo',
  'Totes saved successfully': 'Unità di carico salvate con successo',
  'locations selected': 'ubicazioni selezionate',
  'Print the labels of the selected locations':
    'Stampa le etichette delle ubicazioni selezionate',
  'Locations printed successfully': 'Ubicazioni stampate con successo',
  'Ticket pallet printed successfully': 'Ticket pallet stampati con successo',
  'Tenants invited': 'Tenant invitati',
  'No tenants invited': 'Nessun tenant invitato',
  'Invite tenant': 'Invita tenant',
  'Invite a tenant to the warehouse':
    'Invita un tenant ad accedere al magazzino',
  Prefix: 'Prefisso',
  'Tenant invited successfully': 'Tenant invitato con successo',
  'Tenant rejected successfully': 'Tenant espulso con successo',
  'Invited at %s': 'Invitato in data %s',
  'Delete area %s': "Elimina l'area %s",
  'Edit area': 'Modifica area',
  'Edit area %s': "Modifica l'area",
  'Warehouse deleted successfully': 'Magazzino eliminato con successo',
  'Personal view': 'Vista personale',
  'Shared view': 'Vista condivisa',
  'Default view': 'Vista predefinita',
  'New channel': 'Nuovo canale',
  'Locations update started': 'Aggiornamento ubicazioni iniziato',
  'Changes will be available soon': 'Le modifiche saranno presto disponibili',
  'Reload locations': 'Ricarica ubicazioni',
  'Print ticket pallet': 'Stampa ticket pallet',
  'Location models': 'Modelli di ubicazione',
  'No models available': 'Nessun modello disponibile',
  'Create a new location model': 'Crea un nuovo modello',
  'Add model': 'Aggiungi modello',
  'Create model': 'Crea modello',
  'Edit model': 'Modifica modello',
  'Model saved successfully': 'Modello salvato con successo',
  'Model deleted successfully': 'Modello eliminato con successo',
  'Create a new area': 'Crea una nuova area',
  'Create new totes': 'Crea nuove unità di carico',
  'Select carrier': 'Seleziona corriere',
  'Modify locations': 'Modifica ubicazioni',
  'Modify filtered locations': 'Modifica ubicazioni filtrate',
  'Modify locations status': 'Modifica stato ubicazioni',
  'Modify filtered locations status':
    'Modifica lo stato delle ubicazioni filtrate',
  'Modify selected locations status':
    'Modifica la stato delle ubicazioni selezionate',
  Visibility: 'Visibilità',
  'Locations visibility': 'Visibilità ubicazioni',
  'Modify %s locations': 'Modifica %s ubicazioni',
  Enabled: 'Abilitato',
  Disabled: 'Disabilitato',
  True: 'Vero',
  False: 'Falso',
  'Modify locations model': 'Modifica modello ubicazioni',
  'Modify filtered locations model':
    'Modifica il modello delle ubicazioni filtrate',
  'Modify selected locations model':
    'Modifica il modello delle ubicazioni selezionate',
  Model: 'Modello',
  'Locations model': 'Modello ubicazioni',
  Apply: 'Applica',
  Uncategorized: 'Non categorizzati',
  'All products': 'Tutti i prodotti',
  Tasks: 'Attività',
  'No tasks available': 'Nessuna attività disponibile',
  'Activity execution': 'Esecuzione attività',
  'Context execution': 'Esecuzione raw',
  'Orders update': 'Aggiornamento ordini',
  'Products update': 'Aggiornamento prodotti',
  'Warehouse update': 'Aggiornamento magazzino',
  Completed: 'Completato',
  Failed: 'Fallito',
  Pending: 'In attesa',
  Running: 'In lavorazione',
  'Load more': 'Carica ancora',
  'Total tasks': 'Attività totali',
  'Last tasks': 'Ultime attività',
  'Authorization info are not provided.':
    'Login effettuato su un altro dispositivo.',
  WRITE_CONFLICT: 'Conflitto di aggiornamento',
  'This Product was updated by another User.':
    'Questo prodotto è stato aggiornato da un altro utente. Ricaricalo per poterlo modificare.',
  Delete: 'Elimina',
  'Brand deleted successfully': 'Brand eliminato con successo',
  'Customer deleted successfully': 'Cliente eliminato con successo',
  'Some fields are not filled in correctly':
    'Alcuni campi non sono compilati correttamente',
  'Created at': 'Creato il',
  'Created by': 'Creato da',
  'Updated at': 'Modificato il',
  'Updated by': 'Modificato da',
  'Catalog saved successfully': 'Catalogo salvato con successo',
  'Catalog deleted successfully': 'Catalogo eliminato con successo',
  'The default catalog shoud have the default language':
    'Il catalogo di default dovrebbe avere la lingua di default',
  Warning: 'Attenzione',
  'Storeden missing fields':
    'É necessario mappare tutti i campi obbligatori per configurare correttamente Storeden',
  'Channel saved successfully': 'Canale di vendita creato con successo',
  'Channel deleted successfully': 'Canale di vendita eliminato con successo',
  'Channel sync started': 'Sincronizzazione canale esterno iniziata',
  'Channel integration created successfully':
    'Collegamento canale esterno creato con successo',
  'Channel connected to external channel':
    'Canale di vendita collegato al canale di vendita esterno',
  'Channel disconnected from external channel':
    'Canale di vendita scollegato dal canale di vendita esterno',
  'An unknown error has prevented the connection. Please contact the support.':
    'Errore imprevisto durante il collegamento del canale, contattare il supporto clienti.',
  'Integrated Channels must be disconnected before deletion.':
    "È necessario disconnettere il canale prima dell'eliminazione",
  'Tote without picking-lists': 'Nessuna picking list associata a questa UDC',
  'Picking orders not packable':
    'Nessun ordine da imballare nella picking list',
  'Tote not found': 'UDC non trovata',
  Confirm: 'Conferma',
  Success: 'Successo',
  'Order successfully updated': 'Ordine aggiornato con successo',
  'Order label printed': 'Etichetta ordine stampata',
  'Order not selected': 'Ordine non selezionato',
  'Order successfully packed': 'Ordine imballato con successo',
  'Packing-list started by another user':
    'Packing-list iniziata da un altro utente',
  'Please contact the office': "Contattare l'ufficio",
  'Packaging added to the current packing-list':
    "Imballo aggiunto all'ordine corrente",
  'Picking without totes': 'Picking-list senza UDC',
  'Printers not selected': 'Stampanti non selezionate',
  'Product not contained in the current order':
    "Prodotto non contenuto nell'ordine corrente",
  'Product not contained in the current packing-list':
    'Prodotto non contenuto nella picking-list corrente',
  'Unable to pack this location': "Non è possibile imballare l'UDC corrente",
  SHIPMENT_LABEL_NOT_FOUND: 'Etichetta corriere non trovata',
  'Product already packed': 'Prodotto già imballato',
  'Station not selected': 'Dati postazione non selezionati',
  'Tote Location not found.': 'UDC non trovata',
  'body/email must match format "email"': 'Email non valida',
  'Eject tenant %s from warehouse': 'Rimuovi il tenant %s dal magazzino',
  'Modify model %s': 'Modifica modello %s',
  'Delete model %s': 'Elimina modello %s',
  "Wrapper child's barcode is missing.": 'Barcode imballo mancante.',
  "Wrapper's child is missing.": 'Imballo figlio mancante',
  "Wrapper barcode's quantity mismatch.":
    'Quantità imballi non combaciano con quantità barcode',
  'Quantified barcodes need a wrapper.':
    'Barcode con quantità richiedono un imballo',
  'Packing quantity overflow.': 'Quantità pezzi imballo in eccesso',
  CANCELED: 'Annullato',
  COMPLETED: 'Completato',
  PENDING: 'In attesa',
  'Replenishment saved successfully':
    'Missione di trasferimento creata con successo',
  'Replenishment canceled successfully':
    'Missione di trasferimento annullata con successo',
  'View saved successfully': 'Vista salvata con successo',
  'View deleted successfully': 'Vista eliminata con successo',
  'Invalid credentials.': 'Username e/o password errati',
  'Reset category': 'Reset categorie',
  'Picking-list saved successfully': 'Picking-list salvata con successo',
  'Picking-list deleted successfully': 'Picking-list eliminata con successo',
  'Picking-list printed successfully': 'Picking-list stampata con success',
  'Picking-list missions printed successfully':
    'Missioni picking-list stampate con successo',
  'Picking-list warehouse missing': 'Magazzino di prelievo non selezionato',
  'Quantity to be picked different from the number of total pieces':
    'Quantità da prelevare diversa dal numero di pezzi totali',
  'Multiple orders with the same reference':
    'Sono presenti più ordini con lo stesso riferimento',
  'Orders to pick not available': 'Non ci sono ordini prelevabili',
  'Picking list with more than 10 orders':
    'Picking list per ordine con più di 10 ordini',
  'You are about to delete the picking-list <strong>%s</strong>. Proceed with deletion?':
    "Stai per eliminare la picking-list <strong>%s</strong>. Procedere all'eliminazione?",
  'Delete picking-list': 'Elimina picking-list',
  'Picking-list partial': 'Picking-list parziale',
  NOT_FOUND: 'Elemento non trovato',
  'User view not found.': 'Vista personalizzata non trovata.',
  'Not enough on-hand quantity.':
    'Non è possibile scaricare una quantità maggiore alla giacenza disponibile.',
  'Unable to decrement this batch': 'Non è possibile scaricare questo lotto.',
  BAD_REQUEST: 'Richiesta non accettata',
  'This goods return was updated by another user.':
    'Questo reso è stato aggiornato da un altro utente. Ricaricalo per poterlo modificare.',
  'Carrier not found.': 'Corriere non trovato.',
  'This packing list is closed.': 'La packing-list è chiusa.',
  'This Order was updated by another User.':
    "Quest'ordine è stato aggiornato da un altro utente. Ricaricalo per poterlo modificare.",
  'Orders management': 'Gestione ordini',
  'Warehouse address': 'Indirizzo magazzino',
  'Picking list with an empty order': 'Picking-list con un ordine senza righe',
  'Delete warehouse': 'Elimina magazzino',
  'You are about to delete the warehouse <strong>%s</strong>. Proceed with deletion?':
    "Stai per eliminare il magazzino <strong>%s</strong>. Procedere all'eliminazione?",
  'Delete area': 'Elimina area',
  'You are about to delete the area <strong>%s</strong>. Proceed with deletion?':
    "Stai per eliminare l'area <strong>%s</strong>. Procedere all'eliminazione?",
  'Delete model': 'Elimina modello',
  'You are about to delete the model <strong>%s</strong>. Proceed with deletion?':
    "Stai per eliminare il modello <strong>%s</strong>. Procedere all'eliminazione?",
  CONFLICT: 'Conflitto',
  'Another packing attempt was performed by another User. Please wait 2 minutes before retry.':
    'Etichetta ordine stampata da poco. Attendi 2 minuti prima di procedere.',
  'Too many orders': 'Troppi ordini',
  'User already logged': 'Utente già loggato',
  'An active session already exists for this user. Proceed anyway?':
    'Esiste già una sessione attiva per questo utente. Procedere comunque?',
  Submit: 'Invio',
  'Password changed successfully': 'Password modificata con successo',
  'Password resetted successfully': 'Password resettata con success',
  'You will shortly receive an email with your new temporary password.':
    "A breve riceverai un'email con una nuova password temporanea.",
  'Sessione expired': 'Sessione scaduta',
  'Please log in again to continue.':
    "Effettua l'accesso per poter continuare.",
  'Settings saved successfully': 'Impostazioni salvate con successo',
  'Welcome!': 'Benvenuto!',
  'Tenant selected successfully': 'OrderPod è a tua disposizione',
  'Profile saved successfully': 'Profilo salvato con successo',
  'Available actions': 'Azioni disponibili',
  'Reload brands': 'Ricarica i brand',
  'Delete brand': 'Elimina brand',
  'Delete customer': 'Elimina cliente',
  'You are about to delete the brand <strong>%s</strong>. Proceed with deletion?':
    "Stai per eliminare il brand <strong>%s</strong>. Procedere all'eliminazione?",
  'You are about to delete the customer <strong>%s</strong>. Proceed with deletion?':
    "Stai per eliminare il cliente <strong>%s</strong>. Procedere all'eliminazione?",
  'Customer saved successfully': 'Cliente salvato con successo',
  'users selected': 'utenti selezionati',
  'User saved successfully': 'Utente salvato con successo',
  'Delete user': 'Elimina utente',
  'You are about to delete the user <strong>%s</strong>. Proceed with deletion?':
    "Stai per eliminare l'utente <strong>%s</strong>. Procedere all'eliminazione?",
  'User deleted successfully': 'Utente eliminato con successo',
  'Eject user': 'Espelli utente',
  'You are about to eject the user <strong>%s</strong>. This user will no longer be able to access your tenant. Proceed with ejection?':
    "Stai per espellere l'utente <strong>%s</strong>. Questo utente non potrà più accedere al tuo tenant. Procedere all'espulsione?",
  'User ejected successfully': 'Utente espulso con successo',
  'Invite users': 'Invita utenti',
  'User invited successfully': 'Utente invitato con successo',
  'Manage roles': 'Gestisci ruoli',
  'Roles updated successfully': 'Ruoli aggiornati con successo',
  'Delete role': 'Elimina ruolo',
  'You are about to delete the role <strong>%s</strong>. Proceed with deletion?':
    "Stai per eliminare il ruolo <strong>%s</strong>. Procedere all'eliminazione?",
  'Token created': 'Token creato',
  'API Token created successfully, confirm to copy it to memory.<br /><strong>It will no longer be visible afterwards</strong>.':
    'Token API creato con successo, conferma per copiarlo in memoria.<br /><strong>Successivamente non sarà più visibile</strong>.',
  'Token deleted successfully': 'Token eliminato con successo',
  'Password updated successfully': 'Password aggiornata con successo',
  'Delete bot': 'Elimina integrazione',
  'You are about to delete the bot <strong>%s</strong>. Proceed with deletion?':
    "Stai per eliminare l'integrazione <strong>%s</strong>. Procedere all'eliminazione?",
  'Goods returns': 'Gestione resi',
  'Reload users': 'Ricarica utenti',
  'Invite users in your current tenant': 'Invita degli utenti nel tuo tenant',
  'Manage roles of the selected users':
    'Gestisci i ruoli degli utenti selezionati',
  'Picking-list already started': 'Picking-list già iniziata',
  'Picking-list <strong>%s</strong> already started by <strong>%s</strong>. Proceed with packing?':
    "Picking-list <strong>%s</strong> già iniziata da <strong>%s</strong>. Procedere all'imballo?",
  'Reload categories': 'Ricarica categorie',
  'You are about to delete the channel <strong>%s</strong>. Proceed with deletion?':
    "Stai per eliminare il canale <strong>%s</strong>. Procedere all'eliminazione?",
  'Delete channel': 'Elimina canale di vendita',
  'Category not found.': 'Categoria non trovata.',
  'Orders by channel': 'Ordini per canale',
  'Orders by status': 'Ordini per stato',
  From: 'Da',
  To: 'A',
  Configure: 'Configura',
  'Configure stats': 'Configura statistiche',
  'Reload stats': 'Ricarica statistiche',
}
