import {
  DialogParams,
  Notification,
  NotificationType,
} from '../../../models/notification.model'
import {
  WarehouseNotificationCode,
  WarehouseNotificationData,
  WarehouseNotificationOptions,
} from '../warehouse.model'

export class WarehouseNotification implements Notification {
  public code: WarehouseNotificationCode
  public type: NotificationType
  public dialog?: DialogParams
  constructor({ code, data }: WarehouseNotificationOptions) {
    const { type, dialog } = parseWarehouseNotification(code, data)
    this.code = code
    this.type = type
    this.dialog = dialog
  }

  static from(opts: WarehouseNotificationOptions): WarehouseNotification {
    if (opts instanceof WarehouseNotification) {
      return opts
    } else {
      return new WarehouseNotification(opts)
    }
  }
}

/**
 * Parse warehouse notification to a message
 * @param code - the notification code
 * @param data - the notification data
 * @returns the notification data
 */
export function parseWarehouseNotification(
  code: WarehouseNotificationCode,
  data?: WarehouseNotificationData,
): {
  dialog: DialogParams
  type: NotificationType
} {
  let dialog: DialogParams = {}
  let type: NotificationType = 'notification'

  switch (code) {
    case 'AREA_SAVED':
      dialog = {
        message: 'Area saved successfully',
        color: 'success',
      }
      break
    case 'AREA_DELETED':
      dialog = {
        message: 'Area deleted successfully',
        color: 'success',
      }
      break
    case 'MODEL_SAVED':
      dialog = {
        message: 'Model saved successfully',
        color: 'success',
      }
      break
    case 'MODEL_DELETED':
      dialog = {
        message: 'Model deleted successfully',
        color: 'success',
      }
      break
    case 'LOCATIONS_PRINTED':
      dialog = {
        message: 'Locations printed successfully',
        color: 'success',
      }
      break
    case 'TICKET_PALLET_PRINTED':
      dialog = {
        message: 'Ticket pallet printed successfully',
        color: 'success',
      }
      break
    case 'LOCATIONS_UPDATED':
      dialog = {
        title: 'Locations update started',
        message: 'Changes will be available soon',
        color: 'info',
      }
      break
    case 'WAREHOUSE_SAVED':
      dialog = {
        message: 'Warehouse saved successfully',
        color: 'success',
      }
      break
    case 'WAREHOUSE_DELETED':
      dialog = {
        message: 'Warehouse deleted successfully',
        color: 'success',
      }
      break
    case 'WAREHOUSE_INCOMPLETE':
      dialog = {
        title: 'Attention',
        message: 'Fill out the form with all the necessary data',
        color: 'warning',
      }
      break
    case 'TOTES_SAVED':
      dialog = {
        message: 'Totes saved successfully',
        color: 'success',
      }
      break
    case 'TENANT_INVITED':
      dialog = {
        message: 'Tenant invited successfully',
        color: 'success',
      }
      break
    case 'TENANT_REJECTED':
      dialog = {
        message: 'Tenant rejected successfully',
        color: 'success',
      }
      break
    case 'WAREHOUSE_DELETION': {
      const warehouseName = data?.warehouse?.name
      type = 'alert'
      dialog = {
        icon: 'delete',
        color: 'danger',
        title: 'Delete warehouse',
        message:
          'You are about to delete the warehouse <strong>%s</strong>. Proceed with deletion?',
        messageParams: warehouseName ? [warehouseName] : undefined,
        size: 'lg',
      }
      break
    }
    case 'AREA_DELETION': {
      const areaName = data?.area?.label
      type = 'alert'
      dialog = {
        icon: 'delete',
        color: 'danger',
        title: 'Delete area',
        message:
          'You are about to delete the area <strong>%s</strong>. Proceed with deletion?',
        messageParams: areaName ? [areaName] : undefined,
        size: 'lg',
      }
      break
    }
    case 'MODEL_DELETION': {
      const modelName = data?.model?.label
      type = 'alert'
      dialog = {
        icon: 'delete',
        color: 'danger',
        title: 'Delete model',
        message:
          'You are about to delete the model <strong>%s</strong>. Proceed with deletion?',
        messageParams: modelName ? [modelName] : undefined,
        size: 'lg',
      }
      break
    }
  }

  return { dialog, type }
}
